export async function fetchData(url, params) {
    return fetch(url, params)
    .then(response => response.json())
    .then(data => { return data })
    .catch(err => { return { code: 503, message: "Service Unavailable", data: false } })
}

export const verifyResponse = (response, codeExpected) => {
    if(response.code === codeExpected) {
        return true
    } else {
        return false
    }
}