import React from 'react';
import {  Container } from 'react-bootstrap';
import { Header } from '../components/headers/Header';
import { FormCreateMail } from '../components/emailManager/FormCreateMail';

export function EmailManager() {
 
    return (
        <Container fluid className='p-5'>
            <Header/>
             <FormCreateMail/> 
        </Container>
    );
}