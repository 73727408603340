import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./inputs.css"



export function Select({ listOptions, onChange, disabled, value }) {
    return (
        <div style={disabled ? { opacity: 0.5 } : null} >
            <select
                value={value}
                disabled={disabled}
                className="e-select"
                onChange={(e) => onChange(e.target.value)} >
                {listOptions.map((option, index) => {
                    return <option key={index} value={option.id}>{option.name}</option>
                })}
            </select>
        </div>
    );
}

export function SelectLang({ listOptions, onChange, disabled, value }) {
    return (
        <div style={disabled ? { opacity: 0.5 } : null} >
            <select
                value={value}
                disabled={disabled}
                className="e-select"
                onChange={(e) => onChange(e.target.value)} >
                {listOptions.map((option, index) => {
                    return <option key={index} value={option.value}>{option.name}</option>
                })}
            </select>
        </div>
    );
}

export function DropDownTree({ title, tree, onChange }) {




    const treeView = useRef(null);
    const [levelName, setLevelName] = useState(title);
    const [showDropdown, setShowDropdown] = useState(false);

    const nodeTemplate = (data) => {
        return (
            <div id="treeviewSidebarTemplateFilter" >
                <div className="treeviewdiv">
                    <div className={`d-flex align-items-center`} onClick={() => { onChange(data); setLevelName(data.name); setShowDropdown(false) }}>
                        <div className="txtName  align-items-center c-black font-size-18 d-flex font-weight-regular m-0  me-2">
                            <p className="mb-0">{data.name}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        showDropdown && treeView.current?.expandAll();
    }, [showDropdown])

    useEffect(() => {
        setLevelName(title)
    }, [title])

    return (
        <Dropdown
            className="formDropDownTree"
            drop="down"
            show={showDropdown}
            autoClose="outside">
            <Dropdown.Toggle variant="" id="dropdown-level"  >
                <div className="w-100" onClick={() => setShowDropdown(!showDropdown)}>
                    <h3 className="font-size-15 font-weight-regular d-flex justify-content-between w-100 m-0">{levelName && levelName}</h3>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>

                <TreeViewComponent
                    id="treeview"
                    nodeTemplate={nodeTemplate}
                    ref={treeView}
                    fields={{ dataSource: tree, id: 'identifier', text: 'name', child: 'haschild' }}
                    fullRowSelect={false}
                />
            </Dropdown.Menu>
        </Dropdown>
    );
}


