import { fetchData } from './model'

var base64 = require('base-64');

function UrlServer() {
    return window['REACT_APP_URL_SERVER_' + process.env.REACT_APP_ENVIRONMENT];
}

export async function getCompanies() {
    const method = 'GET'
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64.encode(process.env.REACT_APP_BAUTH_USERNAME + ":" + process.env.REACT_APP_BAUTH_PASSWORD)
    }

    return await fetchData(UrlServer()+window.REACT_APP_SERVICE_STORES_INTERNAL + "/companies?" + window.REACT_APP_SERVICE_V1, { method, headers })
}

export async function getCompanyTree(params) {
    const method = 'GET'
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64.encode(process.env.REACT_APP_BAUTH_USERNAME + ":" + process.env.REACT_APP_BAUTH_PASSWORD)
    }

    return await fetchData(UrlServer()+window.REACT_APP_SERVICE_STORES_INTERNAL + `/tree?company_id=${params.companyID}&${window.REACT_APP_SERVICE_V1}`, { method, headers })
}

export async function insertUser(params) {
    const method = 'POST'
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64.encode(process.env.REACT_APP_BAUTH_USERNAME + ":" + process.env.REACT_APP_BAUTH_PASSWORD)
    }
    const body = JSON.stringify(params.body)

    return await fetchData(UrlServer()+window.REACT_APP_SERVICE_USERS_INTERNAL + "/user?" + window.REACT_APP_SERVICE_V1, { method, headers, body })
}