import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { getCompanies, getCompanyTree, insertUser } from '../../api/fetch';
import { DropDownTree, Select, SelectLang } from '../inputs/inputs';
import './formCreateMail.css'
export function FormCreateMail() {

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [validationMail, setValidationMail] = useState("");
    const [companies, setCompanies] = useState([]);
    const [companySelected, setCompanysSelected] = useState();
    const [treeView, setTreeView] = useState([]);
    const [levelSelected, setLevelSelected] = useState();
    const [languageSelected, setLanguageSelected] = useState("fr");
    const [validateButton, setValidateButton] = useState({ buttonColor: "blue", message: "", disabled: true });

    useEffect(() => {
        getCompanies().then((res) => {
            setCompanies(res.data)
            if (res.data && res.data[0] && res.data[0].id) {
                setCompanysSelected(res.data[0].id)
            }
        });
    }, []);
    useEffect(() => {
        setLevelSelected({ name: "Select a Level" })
        if (companySelected != null) {
            getCompanyTree({ companyID: companySelected }).then((res) => {
                const treeArray = []
                treeArray.push(res.data)
                setTreeView(treeArray)
            });
        }
    }, [companySelected]);

    useEffect(() => {
        if (name.length > 1 &&
            surname.length > 1 &&
            validationMail === "valid" &&
            levelSelected.id
        ) {
            setValidateButton({ buttonColor: "", message: "" })
            setValidateButton({ disabled: false })
        }else{
            setValidateButton({ buttonColor: "", message: "" })
            setValidateButton({ disabled: true })
        }


    }, [name, surname, validationMail, levelSelected,email,languageSelected]);

    function handleName(input) {
        setName(input.target.value)
    }
    function handleSurname(input) {
        setSurname(input.target.value)
    }
    function handleEmail(input) {
        const emailLC = input.target.value.toLowerCase()
        setEmail(emailLC)
        if (emailLC.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            setValidationMail("valid")
        } else {
            setValidationMail("invalid")
        }
    }
    function handleCompany(input) {
        setCompanysSelected(input)
    }
    function handleLanguage(input) {
        setLanguageSelected(input)

    }
    function handleLevel(input) {
        setLevelSelected(input)
    }

    function GenerateMail() {
        let body = {
            name: name,
            surname: surname,
            language: languageSelected,
            company_id: parseInt(companySelected),
            email: email,
            userConfigManageTree: levelSelected,
        }
        setValidateButton({ disabled: true  })
        insertUser({ body: body }).then((data => {
            if (data.code === 200) {
                setValidateButton({ buttonColor: "green", message: "L’account è stato generato con successo" , disabled: true  })
            } else if (data.message === "ErrorAlreadyExist"){
                setValidateButton({ buttonColor: "red", message: "Email Account già esistente", disabled: true  })
            }else {
                setValidateButton({ buttonColor: "red", message: "L’account non è stato generato", disabled: true  })
            }
        }))
    }

    const languages = [{ name: "Italiano", value: "it" }, { name: "Français", value: "fr" }]

    return (
        <>
            <Row className='mt-5'>
                <Col md={3} className='p-2'>
                    <h4 className='mb-3 font-size-16 c-black'>Anagraphic</h4>
                    <h5 className='mb-2 font-size-16 c-black'>Name</h5>
                    <input className='my-input mb-3 font-size-16 c-black'
                        placeholder="Name"
                        onChange={(event) => handleName(event)} />
                    <h5 className='mb-2 font-size-16 c-black'>Surname</h5>
                    <input className='my-input mb-3 font-size-16 c-black'
                        placeholder="Surname"
                        onChange={(value) => handleSurname(value)} />
                    <h5 className='mb-2 font-size-16 c-black'>Email</h5>
                    <input className={`my-input mb-1 font-size-16 c-black ${validationMail}`}
                        placeholder="Email"
                        onChange={(value) => handleEmail(value)} />
                    <p className={`labelValidation mb-3 font-size-14 ${validationMail}`}>
                        {validationMail === "valid" ? "Valid format mail" : validationMail === "invalid" ? "The mail format is not correct" : "insert a valid mail"}
                    </p>
                </Col>
                <Col md={3} className='p-2'>
                    <h4 className='mb-3 font-size-16 c-black'>Pick a Company</h4>
                    <h5 className='mb-2 font-size-16 c-black'>Company List</h5>
                    <Select className='font-size-16 c-black' listOptions={companies} onChange={handleCompany} value={companySelected} />
                </Col>
                <Col md={3} className='p-2'>
                    <h4 className='mb-3 font-size-16 c-black'>Pick a level</h4>
                    <h5 className='mb-2 font-size-16 c-black'>Company’s Tree</h5>
                    <DropDownTree tree={treeView} title={levelSelected && levelSelected.name} onChange={handleLevel} />
                </Col>
                <Col md={3} className='p-2'>
                    <h4 className='mb-3 font-size-16 c-black'>Pick a language</h4>
                    <h5 className='mb-2 font-size-16 c-black'>Languages</h5>
                    <SelectLang listOptions={languages} onChange={handleLanguage} value={languageSelected} />
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col md={12} className='text-center'>
                    <Button
                        disabled={validateButton.disabled}
                        className={`mt-5 formMail ${validateButton.buttonColor}`} onClick={GenerateMail}>Generate</Button>
                    <p className={`labelValidation mb-3 font-size-14 c-${validateButton.buttonColor}`}>
                        {validateButton.message}
                    </p>
                </Col>
            </Row>

        </>
    );
}