import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './header.css'


export function Header() {
 
    return (
   
            <Row id='Header'>
            <Col xs={12}>
            <h3 className='mb-0 font-size-16'>I’m ok 2022</h3>
             </Col>
            <Col xs={12}>
            <h1 className='mb-3 font-size-56'>Saga Company Portal</h1>
             </Col>
            </Row>
 
    );
}